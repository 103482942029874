/** Status de Cálculos de Timesheets, potêncialmente outros */
export const CalculationStatusEnum = {
  ABERTO: 'aberto',
  FECHADO: 'fechado',
};

/** Status detalhado dos calculos feitos pelo Teros */
export const StatusCalculoEnum = {
  PENDENTE: -2,
  EM_PROCESSAMENTO: -1,
  AGUARDANDO_CALCULO: 0,
  CALCULADO: 1,
  FECHADO: 11
};

export const StatusCalculoTranslation = {
  [StatusCalculoEnum.PENDENTE]: "Pendente",
  [StatusCalculoEnum.EM_PROCESSAMENTO]: "Em Processamento",
  [StatusCalculoEnum.AGUARDANDO_CALCULO]: "Aguardando Calculo",
  [StatusCalculoEnum.CALCULADO]: "Calculado",
  [StatusCalculoEnum.FECHADO]: "Fechado"
};
